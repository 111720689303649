import React from "react";
import { NavLink, Link } from "react-router-dom";
import s from "./Header.module.css";
import arrow_back from "../../../img/arrow-back.png";
import { useState, useEffect } from "react";
import { UserMenu } from "./UserMenu/UserMenu";
import { useSelector } from "react-redux/es/exports";
import { Navigate, useLocation } from "react-router";
import ModalContainerNew from "../../../Components/Modal/ModalContainerNew";
import Button from "../../../Components/Button/Button";
import { nativeSendMessage } from "../../../base/nativeController";
import { useUnit } from "effector-react";
import {
  $deviceId,
  $showPushModal,
  setAppEvent,
} from "../../../base/store/appStore";
import { setUserDevicePushStatus } from "../../../base/asyncActions/setUserDevicePushStatus";
import { getRoute, useHistoryUp } from "../../../base/utils";

const Header = () => {
  let Text = useSelector((state) => state.config.header_text);
  var url = document.location.pathname;
  const [deviceId, showPushModal, setApp] = useUnit([
    $deviceId,
    $showPushModal,
    setAppEvent,
  ]);
  useEffect(() => {
    //код закрывающий все треки
    let match = window.location.pathname.match("/consultation/");
    if (url !== "/profile/utility" && !match) {
      window.scrollTo(0, 0);
      window.localStreamVideo?.getTracks().forEach((track) => {
        track.stop();
      });
      window.localStreamAudio?.getTracks().forEach((track) => {
        track.stop();
      });
      window.localStreamAudioDisabled?.shutdown();
    }
  }, [url]);
  const config = useSelector((state) => state.config.config);
  const titlePostfix = config?.config.title ? config?.config.title : "Telemed";
  document.title = Text + " - " + titlePostfix;
  const [isHover, setIsHover] = useState(false);
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [route, setRoute] = useState(null);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEnter1 = () => {
    setIsHover1(true);
  };
  const handleMouseLeave1 = () => {
    setIsHover1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHover2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHover2(false);
  };

  const historyUp = useHistoryUp();

  const mainPages = ["Главная", "Мои записи", "Мои врачи", "Профиль", "Оплата"];

  const styleBlue = {
    borderBottom: !isHover
      ? `4px solid ${config?.config.colors.color3}`
      : `4px solid ${config?.config.colors.color10}`,
    color: config?.config.colors.color2,
  };
  const styleBlue1 = {
    borderBottom: !isHover1
      ? `4px solid ${config?.config.colors.color3}`
      : `4px solid ${config?.config.colors.color10}`,
    color: config?.config.colors.color2,
  };
  const styleBlue2 = {
    borderBottom: !isHover2
      ? `4px solid ${config?.config.colors.color3}`
      : `4px solid ${config?.config.colors.color10}`,
    color: config?.config.colors.color2,
  };

  useEffect(() => {
    if (config?.status && config?.push_enabled == null) {
      setPushModal(true);
    }
  }, [config]);

  const [pushModal, setPushModal] = useState(true);

  const handlePushEnalble = (status) => {
    setApp({ showPushModal: false });
    nativeSendMessage({
      action: "pushEnabled",
      status,
    });
  };

  const location = useLocation();

  useEffect(() => {
    const route = getRoute(location);
    setRoute(route);
  }, [location]);

  const is_fullpage = url === "/install";

  if (is_fullpage) {
    return null;
  }

  return config &&
    !config.token &&
    ((route &&
      route !== "docs/:type" &&
      route !== "pay/:id" &&
      route !== "install") ||
      route === false) ? (
    <>
      {localStorage.clear()}
      <Navigate to="login" />
    </>
  ) : config?.token ? (
    <div className={s.Header_container + " Container"}>
      {config?.platform === "app" && showPushModal ? (
        <ModalContainerNew
          openModal={pushModal}
          setOpenModal={(status) => {
            setPushModal(status);
          }}
          classPost="smallModal"
          title="Включить пуш уведомления?"
          onClose={() => {
            nativeSendMessage({
              action: "allowRefresh",
              data: { status: true, here: 2 },
            });
            handlePushEnalble(false);
          }}
        >
          <div style={{ maxHeight: "40vh", textAlign: "center" }}>
            <div style={{ height: "180px", width: "auto" }}>
              <img
                style={{ height: "100%" }}
                alt=""
                src={config.config.images.think}
              />
            </div>
            <div className={s.Cancel_Record_button}>
              <div
                onClick={() => {
                  setPushModal(false);
                  nativeSendMessage({
                    action: "allowRefresh",
                    data: { status: true, here: 1 },
                  });
                  handlePushEnalble(true);
                }}
              >
                <Button
                  type={"submit"}
                  class={"btn orange"}
                  className={s.Font_size16}
                  text={"Да"}
                />
              </div>
              <div
                onClick={() => {
                  setPushModal(false);
                  nativeSendMessage({
                    action: "allowRefresh",
                    data: { status: true, here: 3 },
                  });
                  handlePushEnalble(false);
                  setUserDevicePushStatus(deviceId, false);
                }}
              >
                <Button
                  type={"submit"}
                  className={s.Font_size16}
                  class={"btn white"}
                  text={"Нет"}
                />
              </div>
            </div>
          </div>
        </ModalContainerNew>
      ) : null}
      <header className={s.Header_full}>
        <div className={s.Header_logo}>
          <Link to={"main"}>
            <img alt="" src={config.config.logo} />
          </Link>
        </div>
        <div className={s.Header_nav}>
          <nav>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
              style={({ isActive }) =>
                isActive
                  ? {
                      borderBottom: `4px solid ${config?.config.colors.color10}`,
                      color: config?.config.colors.color2,
                    }
                  : styleBlue
              }
              to="main"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Главная
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
              style={({ isActive }) =>
                isActive
                  ? {
                      borderBottom: `4px solid ${config?.config.colors.color10}`,
                      color: config?.config.colors.color2,
                    }
                  : styleBlue1
              }
              to="my-consultation"
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              Мои записи
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${s.navLink} ${s.headerActiveLink}` : s.navLink
              }
              style={({ isActive }) =>
                isActive
                  ? {
                      borderBottom: `4px solid ${config?.config.colors.color10}`,
                      color: config?.config.colors.color2,
                    }
                  : styleBlue2
              }
              to="my-doctor"
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              Мои врачи
            </NavLink>
          </nav>
        </div>
        <UserMenu type="login" />
      </header>
      <div className={s.Menu_mobile}>
        {!mainPages.includes(Text) ? (
          <img alt="" src={arrow_back} onClick={(e) => historyUp()} />
        ) : (
          ""
        )}
        <div className={s.Menu_mobile_title + " title_config"}>
          <h1>{Text}</h1>
        </div>
      </div>
    </div>
  ) : (
    <div className={s.Header_container + " Container"}>
      <header className={s.Header_full}>
        <div className={s.Header_logo}>
          <Link to={"login"}>
            <img alt="" src={config.config.logo} />
          </Link>
        </div>
      </header>
      <div className={s.Menu_mobile}>
        {!mainPages.includes(Text) ? (
          <img alt="" src={arrow_back} onClick={(e) => historyUp()} />
        ) : (
          ""
        )}
        <div className={s.Menu_mobile_title + " title_config"}>
          <h1>{Text}</h1>
        </div>
      </div>
    </div>
  );
};
export default Header;
