import React from "react";
import s from "./Doctor_list.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { axiosDoctor } from "../../../base/asyncActions/getDoctors";
import SelectLogin from "../../../Components/Select/SelectLogin/SelectLogin";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import { Link } from "react-router-dom";
import Chat from "../../../Components/Chat/Chat";
import Button from "../../../Components/Button/Button";
import Skeleton from "react-loading-skeleton";
const DoctorList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let callendarDay = (date) => {
    const days = [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Субота",
      ],
      now = new Date(date);
    return days[now.getDay()];
  };
  const params = useParams();
  const Doctors = useSelector((state) => state.doctorSpec.Doctor_array);
  const BranchName = useSelector((state) => state.doctorSpec.branch_name);
  let Name = useSelector((state) => state.doctorSpec.specialization_name);
  let Title = useSelector((state) => state.config.titleBranch);
  let [statusDoc, setStatus] = useState(false);
  let [BranchTitle, setBranchTitle] = useState(Name);
  let [BranchTitle1, setBranchTitle1] = useState("");
  const arraySort = [
    {
      title: "По популярности",
      branch_id: "rate",
    },
    {
      title: "Цена по убыванию",
      branch_id: "price_desc",
    },
    {
      title: "Цена по возрастанию",
      branch_id: "price_asc",
    },
  ];
  let page = useSelector((state) => state.doctorSpec.page);
  let totalPage = useSelector((state) => state.doctorSpec.totalPage);
  let spec_array = useSelector((state) => state.doctorSpec.spec_array);
  const sort = useSelector((state) => state.doctorSpec.sort);
  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(
        axiosDoctor(page, params.code, "rate", params.spec_code)
      );
      setStatus(statusDoctor.status);
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setBranchTitle1(BranchName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BranchName]);
  useEffect(() => {
    setBranchTitle(Title);
    let branchTitle = BranchTitle1 ? BranchTitle1 + ": " + Title : "Доктора";
    dispatch(getConfigHeaderAction(branchTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Title, BranchTitle1]);
  const sendRequest = () => {
    if (page < totalPage)
      dispatch(axiosDoctor(++page, params.code, sort, params.spec_code));
  };

  useEffect(() => {
    const spec = spec_array.find((item) => {
      return item.code === params.spec_code;
    });
    if (spec) {
      setBranchTitle(spec.title);
    }
  }, [params.spec_code, spec_array]);

  let Doctor = Doctors.map((el, key) => (
    <div className={s.Doctor + " black_config"} key={key}>
      <div className={s.Doctor_infos}>
        <div className={s.Doctor_avatar}>
          <div className={s.Doctor_avatar_img}>
            <img alt="" src={el.photo} />
          </div>
        </div>
        <div className={s.Doctor_info + " " + s.black}>
          <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
            {el.specialization.join(" • ")}
          </p>
          <h2 className={s.Font_size24}>
            {el.lastname + " " + el.firstname + " " + el.secondname}
          </h2>
          <p className={s.Staj + " " + s.Font_size14}>
            {el.regalia.join(" • ")}
          </p>
          <div className={s.Doctor_buy}>
            <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
              Стоимость консультации:
            </p>
            <p className={s.buy + " " + s.Font_size24}>{el.price} ₽</p>
          </div>
        </div>
      </div>
      <div className={s.Payment_block}>
        <div className={s.Payment_block_p}>
          <p
            className={
              s.Closes_title +
              " " +
              s.gray +
              " " +
              s.Font_size14 +
              " gray_config"
            }
          >
            Ближайшая запись:
          </p>
          <p className={s.Font_size14}>
            {el.closest_datetime
              ? callendarDay(el.closest_datetime) +
                ", " +
                new Date(el.closest_datetime).toLocaleString("ru", {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              : "Отсутствует"}
          </p>
        </div>
        <Link to={"/doctor/" + el.doctor_id}>
          <Button
            className={s.Reviews_send + " " + s.Font_size14}
            type={"submit"}
            class={"btn blue"}
            text={"Записаться"}
          />
        </Link>
      </div>
    </div>
  ));

  let sceletonMap = [];
  for (var i = 0; i < 3; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <div className={s.Doctor + " black_config"} key={sceletionId}>
        <div className={s.Doctor_infos}>
          <div className={s.Doctor_avatar}>
            <div className={s.Doctor_avatar_img}>
              <Skeleton circle="1" style={{ height: "100%" }} />
            </div>
          </div>
          <div className={s.Doctor_info + " " + s.black}>
            <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
              <Skeleton style={{ width: "95%" }} />
            </p>
            <h2 className={s.Font_size24}>
              <Skeleton style={{ width: "100%" }} />
            </h2>
            <p className={s.Staj + " " + s.Font_size14}>
              <Skeleton style={{ width: "80%" }} />
            </p>
            <div className={s.Doctor_buy}>
              <p className={s.gray + " " + s.Font_size14 + " gray_config"}>
                <Skeleton style={{ width: "60%" }} />
              </p>
              <p className={s.buy + " " + s.Font_size24}>
                <Skeleton style={{ width: "30%" }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className={s.Container + " Container"}>
      <section className={s.Doctor_list}>
        <div className={s.Skill}>
          <div className={s.Skill_title}>
            <h1 className={s.Font_size24 + " title_config"}>
              {BranchTitle1 ? BranchTitle1 + ": " + BranchTitle : ""}
            </h1>
          </div>
          <div className={s.Select_all}>
            <div className={s.Skill_select}>
              <p className={s.Font_size14 + " gray_config"}>Специализация</p>
              <SelectLogin
                array={spec_array}
                selectType={"specialization"}
                func={axiosDoctor}
                category_id={params.code}
              />
            </div>
            <div className={s.Sort_select}>
              <p className={s.Font_size14 + " gray_config"}>Сортировка</p>
              <SelectLogin
                array={arraySort}
                selectType={"sort"}
                func={axiosDoctor}
              />
            </div>
          </div>
        </div>
        {statusDoc ? Doctor : loader}
      </section>
      {page < totalPage ? (
        <div className={s.Doctor_button}>
          <div className={s.Doctor_button_mobile} onClick={sendRequest}>
            <Button
              className={s.Reviews_send + " " + s.Font_size14}
              type={"submit"}
              class={"btn white"}
              text={"Показать ещё"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <Chat />
    </div>
  );
};
export { DoctorList };
