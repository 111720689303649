import axios from "axios";
import { defaultUrl } from "../configUrl";

export const getUserDevicePushStatus = async (deviceId) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const response = await axios.get(
    `${defaultUrl}user/device/${deviceId}/notification`
  );
  return response.data?.status ?? false;
};
