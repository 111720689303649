import React, { useState } from "react";
import s from "./Result.module.css";
import "./drop.css";
import axios from "axios";
import { defaultUrl } from "../../../../base/configUrl";
import { useSelector } from "react-redux";
import { nativeSendMessage } from "../../../../base/nativeController";

const ResultItem = ({ el, select, onSelect, chatView }) => {
  const [downloading, setDownloading] = useState([]);
  const config = useSelector((state) => state.config.config);

  const formatBytes = (bytes) => {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(2) + " MB";
    } else {
      return (bytes / 1073741824).toFixed(2) + " GB";
    }
  };

  const downloadFile = (research_uuid, file_uuid) => {
    const token = localStorage.getItem("token");
    if (token) {
      const isChromium = !!window.chrome;
      const downloadText = "Загрузка...";
      const newDownloading = JSON.parse(JSON.stringify(downloading));
      newDownloading.push(file_uuid);
      setDownloading(newDownloading);
      let loadingState = false;
      let downloadWindow;
      if (!isChromium && config.platform !== "app") {
        downloadWindow = window.open("");
        downloadWindow.document.body.innerHTML = downloadText;
      }
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios
        .get(`${defaultUrl}user/research/${research_uuid}/${file_uuid}`)
        .then((response) => {
          loadingState = true;
          if (response.data.status && response.data.url) {
            if (config.platform !== "app") {
              if (downloadWindow) {
                downloadWindow.location = response.data.url;
                downloadWindow.addEventListener("load", () => {
                  downloadWindow.close();
                });
              } else {
                window.open(response.data.url);
              }
            } else {
              nativeSendMessage({
                action: "downloadFile",
                data: { url: response.data.url, name: response.data?.name },
              });
            }
          } else {
            alert("Ошибка загрузки файла");
          }
        })
        .catch((error) => {
          alert("Ошибка загрузки файла");
        })
        .finally(() => {
          const newDownloading = JSON.parse(JSON.stringify(downloading));
          const filteredArray = newDownloading.filter(function (e) {
            return e !== file_uuid;
          });
          setDownloading(filteredArray);
          if (downloadWindow && !loadingState) {
            downloadWindow.close();
          }
        });
    }
  };

  return (
    <div style={chatView ? { width: "100%" } : {}}>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "10px",
          justifyContent: "space-between",
          fontSize: "14px",
          gap: "20px",
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        <div>
          <div style={{ fontSize: "12px" }}>
            {el.type === 1 ? "Лабораторные" : "Функциональные"}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "600" }}>{el.name}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#6f6f6f",
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            {el.tags &&
              el.tags.map((tag) => {
                return <div key={tag.id}>{tag.name}</div>;
              })}
          </div>
        </div>
        <div>
          <div>
            {el.files
              ? el.files.map((file) => {
                  console.log("downloading render", downloading);
                  return (
                    <div
                      key={file.uuid}
                      style={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "end",
                        alignItems: "end",
                      }}
                    >
                      {!downloading.includes(file.uuid) ? (
                        <div
                          onClick={() => downloadFile(el.uuid, file.uuid)}
                          style={{
                            cursor: "pointer",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "right",
                            maxWidth: "150px",
                            textWrap: "nowrap",
                            color: "#295183",
                          }}
                        >
                          {file.name}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#6f6f6f",
                            textWrap: "nowrap",
                          }}
                        >
                          Загрузка...
                        </div>
                      )}
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#6f6f6f",
                          textWrap: "nowrap",
                        }}
                      >
                        {formatBytes(file.size)}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      {select ? (
        <div
          className={s.AttachFile}
          onClick={() => {
            onSelect(el);
          }}
        >
          Прикрепить
        </div>
      ) : null}
    </div>
  );
};

export default ResultItem;
