import React, { useEffect, useState } from "react";
import s from "./Result.module.css";
import { Input } from "../../../../Components/Input/Input";
import { axiosProfileResult } from "../../../../base/asyncActions/Profile";
import InfoModal from "../../../../Components/InfoText/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Components/Loading/Loader";
import SelectResult from "../../../../Components/Select/SelectResult/SelectResult";
import { FileUploader } from "react-drag-drop-files";
import FormErrors from "../../../../Components/FormError/FormError";
import Button from "../../../../Components/Button/Button";
import { axiosProfileUpload } from "../../../../base/asyncActions/Profile";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import "./drop.css";
import ResultItem from "./ResultItem";

const FormResult = () => {
  let dispatch = useDispatch();
  let [fileUpload, setFile] = useState("");
  const config = useSelector((state) => state.config.config);
  const [fileName, setFileName] = useState("");
  const handleChangeFile = async (file) => {
    setFile(file);
  };
  const handleChangeName = (e) => {
    setFileName(e.target.value);
  };
  const handleChange = async (e) => {
    e.preventDefault();
    let obj = {};
    const data = await new FormData(e.target);
    [...data].forEach((e) => {
      obj[e[0]] = e[1];
    });
    obj.file = fileUpload;
    let response = await dispatch(axiosProfileUpload(obj));

    if (!response.status) {
      seterrorType((errorType) => ({
        error: {
          fields: {
            type: response.error?.fields.type
              ? [...response.error.fields.type]
              : [],
            name: response.error?.fields.name
              ? [...response.error.fields.name]
              : [],
            file: response.error?.fields.file
              ? [...response.error.fields.file]
              : [],
          },
        },
      }));
      seterrorMessage((errorMessage) => ({
        error: {
          message: response.error?.message,
        },
      }));
    } else {
      setFileName("");
      setFile(false);
      dispatch(axiosProfileResult(1, 0, true));
    }
  };

  let [errorMessage, seterrorMessage] = useState({
    status: false,
    error: {
      message: "",
    },
  });
  let [errorType, seterrorType] = useState({
    status: false,
    error: {
      fields: {
        type: [],
        name: [],
        file: [],
      },
    },
  });

  return (
    <form onSubmit={handleChange}>
      <FormErrors error={errorType.error?.fields.type} />
      <div className={s.Form_Download}>
        <FileUploader
          handleChange={handleChangeFile}
          fileOrFiles={fileUpload}
          id={"Upload"}
          label="Нажмите или перетащите сюда файл"
          name="file"
          classes="drop_area"
        />
        <label htmlFor="Upload" className={s.labelDownload}>
          {!fileUpload ? "Нажмите или перетащите сюда файл" : fileUpload.name}
        </label>
      </div>
      <FormErrors error={errorType.error?.fields.file} />
      {fileUpload ? (
        <>
          <Input
            name={"name"}
            minLength={"2"}
            required
            type="text"
            placeholder="Название документа"
            value={fileName}
            onChange={handleChangeName}
          />
          <FormErrors error={errorType.error?.fields.name} />
          <div className={s.Form_Radio}>
            <div className={s.Form_Input} style={{}}>
              <Input
                id="Register_radio1"
                value={"1"}
                type="radio"
                name={"type"}
                labeltext={"Лабораторные"}
                label={{
                  margin: "0px 10px 0px 10px",
                  color: config?.config.colors.color4,
                }}
              />
              <InfoModal
                className={s.Info}
                text="Любые исследования, которые сдали в лаборатории – кровь, мазки, биопсия и прочее."
                classtwo="infoLabpop"
                class="infoLab"
              />
            </div>
            <div className={s.Form_Input}>
              <Input
                id="Register_radio2"
                value={"2"}
                type="radio"
                name={"type"}
                labeltext={"Инструментальные"}
                label={{
                  margin: "0px 10px 0px 40px",
                  color: config?.config.colors.color4,
                }}
              />
              <InfoModal
                text="Все исследования, которые проводились с использованием технических решений – УЗИ, МРТ, КТ, ЭКГ и прочее."
                classtwo="infoFuncpop"
                class="infoFunc"
              />
            </div>
          </div>

          <div className={s.UploadFile} style={{ marginTop: "20px" }}>
            <Button
              className={s.Font_size14}
              type={"submit"}
              class={"btn blue"}
              text={"Загрузить исследование"}
            />
          </div>
        </>
      ) : null}
      <div className={s.Form_Line}></div>
      {/* КОМПОНЕНТ ОШИБКИ */}
      <FormErrors error={errorMessage.error.message} />
      {/* КОМПОНЕНТ ОШИБКИ */}
    </form>
  );
};

const Result = ({ select, onSelect, ignoreHeader }) => {
  let dispatch = useDispatch();
  let file = useSelector((state) => state.profile.file_history);
  let [statusDoc, setStatus] = useState(false);
  const config = useSelector((state) => state.config.config);
  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(axiosProfileResult(1, 0, true));
      setStatus(statusDoctor.status);
      if (!ignoreHeader) {
        dispatch(getConfigHeaderAction("Результаты"));
      }
    }
    fetchMyAPI();
    // let title = config?.config.title ? config?.config.title : "Telemed";
    // document.title = 'Результаты исследований - ' + title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
  let page = useSelector((state) => state.profile.current_page);
  let total_page = useSelector((state) => state.profile.total_page);
  let type_file = useSelector((state) => state.profile.type_file);
  const onClickShow = () => {
    if (page++ > total_page) {
    } else dispatch(axiosProfileResult(page++, type_file));
  };
  const arraySort = [
    {
      title: "Все",
      branch_id: "0",
    },
    {
      title: "Лабораторные",
      branch_id: "1",
    },
    {
      title: "Инструментальные",
      branch_id: "2",
    },
  ];

  return (
    <div className={(!select ? s.ResultFull : "") + " black_config"}>
      <div className={s.title + " title_config"}>
        <h1>
          {!select ? "Результаты исследований" : "Прикрепить исследование"}
        </h1>
      </div>
      <div style={{ marginTop: "10px" }} className={s.formDownload}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {console.log(config)}
          <p className={s.Font_size24}>Прикрепить иследование</p>{" "}
          <InfoModal
            text={`В данном разделе вы можете удобно хранить всю свою медицинскую документацию из любых медицинских центров, а так же получите готовое исследование, пройденное в ${config.config.title} (если предупредили об этом регистратуру ДО момента сдачи анализа).`}
            classtwo="infoFuncpop"
            class="infoFunc"
          />
        </div>
        <FormResult />
      </div>
      <div className={s.Download_File}>
        <SelectResult array={arraySort} />
        <div className={s.Download_File_full}>
          <div className={s.Form_Line}></div>
          {statusDoc ? (
            <div style={{ marginBottom: "20px" }}>
              {file && file.length ? (
                file.map((el) => (
                  <ResultItem
                    key={el.uuid}
                    el={el}
                    select={select}
                    onSelect={onSelect}
                  />
                ))
              ) : (
                <>У вас еще нет иследований</>
              )}
            </div>
          ) : (
            <Loader />
          )}
          {file && file.length ? (
            <div className={s.Message_button_margin} onClick={onClickShow}>
              <Button
                className={s.Show_more + " " + s.Font_size14}
                type={"submit"}
                class={"btn white"}
                text={"Показать ещё"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Result;
